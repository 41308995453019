import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import React from "react";
import storage from '../../../framework/src/StorageProvider';
import  { isValidPhoneNumber } from "react-phone-number-input";
import {setStorageData, getStorageData,removeStorageData } from "../../../framework/src/Utilities";
import momentX from "moment-timezone";
import {constantPass} from '../../../components/src/Constants.web'
interface MyComponentState {
  // Define your state properties here
  emirate: string;
  area: string;
  gender: string
  nationality: string
  residence: string
  // ... other properties
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  inputs: string;
  emirate: string;
  tooltipOpenchannelHeadingTooltip:any;
  area: string;
  gender: string;
  number: any;
  dateofbirth: any;
  nationality: string;
  residence: string;
  password: string;
  confirmPassword: string;
  pets: string[];
  enablePasswordField: boolean;
  passwordError: boolean;
  confirmPasswordError:boolean;
  passwordNotMatchMessage:string;
  dateOfBirthMessage: string;
  emirateError: boolean;
  areaError: boolean;
  dateofbirthError: boolean;
  heading: string;
  phoneError: boolean;
  residenceError: boolean;
  time: any,
  isActive: boolean,
  genderError: boolean;
  newEntry: any;
  otpDone: boolean
  petsError: boolean;
  fomatMObileNumber: any;
  OtpError: boolean
  messageSnackbar: string;
  rescueGroup: string
  groupName: string
  success: boolean
  groupContact: any,
  rescueGroupError: boolean
  email: string;
  groupNameError: boolean
  groupContactError: boolean
  groupContactValue: any
  groupContactFormatValue: string
  errors: any
  otpFalse: boolean
  emirateData: any;
  areaData: any;
  nationalitiesData: any;
  countriesData: any;
  veterinaryEntries: any;
  rescueCheck: boolean;
  otpErrorinApi: string
  formattedValue: any;
  facebookLink: string;
  tiktokLink: string
  phoneErrorMessage: string
  open:boolean
  info:string
  snapchatLink: string
  linkdinLink: string;
  instagramLink: string
  userType: string
  isSnackbarOpen: boolean
  facebookLinkError: boolean;
  tiktokLinkError: boolean
  snapchatLinkError: boolean
  firstName: string;
  linkdinLinkError: boolean;
  selectedCountry:any;
  instagramLinkError: boolean
  rescurCountry:any;
  enableConfirmPasswordField:boolean;
  toolTip:boolean;
  toolTip2:boolean;
  city:string;
  cityError:boolean;
  signUpFormImage:string;
  datePickerIsOpen:boolean
  resetImage:string
  otpVerifyImage:string;
  isPageActive:boolean
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailOtpVerifyController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  inputRefs: any;
  timer: any;
  timeoutId : NodeJS.Timeout | null;
  otpverifyId: string = ""
  resendApiId: string = ""
  signupApiAdopterId: string = ""
  signupApiRescuerId: string = ""
  forgotResentOtpId: string = "";
  emirateApiId: string = ""
  areaApiId: string = ""
  nationalitiesApiId: string = ""
  countriesApiId: string = ""
  getProfileApiId:string=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      inputs: "",
      rescueCheck: false,
      emirate: "Select",
      tooltipOpenchannelHeadingTooltip:false,
      firstName: "",
      selectedCountry:"",
      area: "",
      signUpFormImage:"",
      gender: "Select",
      heading: "",
      rescurCountry:"",
      number: "",
      isSnackbarOpen: false,
      messageSnackbar: "",
      facebookLinkError: false,
      open:false,
  info:"",
      tiktokLinkError: false,
      snapchatLinkError: false,
      dateOfBirthMessage: "Please select a date",
      linkdinLinkError: false,
      emirateData: [],
      areaData: [],
      nationalitiesData: [],
      countriesData: [],
      instagramLinkError: false,
      dateofbirth: "",
      nationality: "Select",
      veterinaryEntries: [
        {
          veterinaryName: "",
          clinicsName: "",
          contactNumber: "",
          formattedValue: "",
          formattedcontactNumber: "",
          country:""
        },
      ],
      rescueGroup: "",
      groupName: "",
      otpErrorinApi: "",
      groupContact: "",
      otpDone: false,
      rescueGroupError: false,
      otpFalse: false,
      groupNameError: false,
      groupContactError: false,
      email: "",
      groupContactValue: "",
      groupContactFormatValue: "",
      errors: [
        {
          veterinaryNameError: false,
          clinicsNameError: false,
          contactNumberError: false,
        },

      ],
      newEntry: {
        veterinaryName: "",
        clinicsName: "",
        formattedValue: "",
        contactNumber: "",
        formattedcontactNumber: ""
      },
      residence: "Select",
      password: "",
      confirmPassword: "",
      pets: [],
      fomatMObileNumber: "",
      formattedValue: "",
      enablePasswordField: true,
      passwordError: false,
      confirmPasswordError:false,
      passwordNotMatchMessage:constantPass.passwordNotMatchMessage,
      emirateError: false,
      areaError: false,
      dateofbirthError: false,
      phoneError: false,
      residenceError: false,
      genderError: false,
      petsError: false,
      time: { minutes: 0, seconds: 59 },
      isActive: true,
      OtpError: false,
      phoneErrorMessage: "",
      facebookLink: "",
      tiktokLink: "",
      snapchatLink: "",
      linkdinLink: "",
      instagramLink: "",
      resetImage:"",
      otpVerifyImage:"",
      userType: "",
      success: false,
      enableConfirmPasswordField:true,
      toolTip:false,
      toolTip2:false,
      city:"",
      cityError:false,
      datePickerIsOpen:false,
      isPageActive:false
      // Customizable Area End
    };

    // Customizable Area Start
    this.inputRefs = Array.from({ length: 5 }, () => React.createRef());
    this.timer = null;
    this.timeoutId = null;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start   
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
    }

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && !responseJson.error) {
      switch (apiRequestCallId) {
        case this.otpverifyId:
          this.handleOtpVerifyResponse(responseJson)
          break;
        case this.resendApiId:
          this.resetTimer()
          this.setState({ otpFalse: false })
          storage.set("otp_token", responseJson?.meta?.otp_token)
          storage.set("token", responseJson?.meta?.token)
          break;
        case this.signupApiAdopterId:
          this.hanldeSignUpResponse(responseJson);
          break;
        case this.signupApiRescuerId:
          this.hanldeSignUpResponse(responseJson);
          break;
        case this.nationalitiesApiId:
          this.setState({ nationalitiesData: responseJson })
          break;
        case this.areaApiId:
          this.setState({ areaData: responseJson })

          break;
        case this.countriesApiId:
          this.setState({ countriesData: responseJson })

          break;
        case this.emirateApiId:
          this.setState({ emirateData: responseJson })

          break;
        case this.forgotResentOtpId:
          this.resetTimer()
          this.setState({ otpFalse: false })
          storage.set("otp_token", responseJson?.meta?.otp_token)
          break;
          case this.getProfileApiId:
            const data=responseJson?.data?.attributes
           this.handleSetStateFunction(data)
            this.setState({
                   
              pets:data?.pets_references||"",
              facebookLink:data?.facebook_url||"",
              tiktokLink:data?.tiktok_url||"",
              linkdinLink:data?.linkedin_url||"",
              snapchatLink:data?.snapchat_url||"",
              instagramLink:data?.instagram_url||""
          
            })
            break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  handleInactivityCheck = () => {
    this.resetTime()
    const events = ['mousemove', 'keydown', 'scroll', 'click'];
    events.forEach((event) => {
      window.addEventListener(event, this.resetInactivityTimer);
    });
  }

  resetTime = () => {
    this.timeoutId = setTimeout(() => {
      if (!this.state.isPageActive) {
        localStorage.clear()
        this.logOutUser()
      }
    }, 3600000);
  }

  logOutUser = () => {
    const gotoOrders: Message = new Message(getName(MessageEnum.NavigationMessage));
    gotoOrders.addData(getName(MessageEnum.NavigationTargetMessage), "LandingPageWeb");
    gotoOrders.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(gotoOrders);
  }

  resetInactivityTimer = () => {
    clearTimeout(this.timeoutId as NodeJS.Timeout);
    this.resetTime()
  };

  handleOtpVerifyResponse = async (responseJson: any) => {
    if (responseJson?.errors && responseJson?.errors[0]?.pin) {
      this.setState({ isSnackbarOpen: true, messageSnackbar: responseJson?.errors[0]?.pin, time: { minutes: 0, seconds: 0 }, inputs: "" })
    }
    else if (responseJson?.errors && responseJson?.errors[0]?.otp) {
      this.setState({ OtpError: true })

    }
    else if (responseJson?.messages && responseJson?.messages[0]?.otp === "OTP validation success") {
      if (this.state.heading === "Password Recovery") {
        this.props.navigation.navigate("ResetPasswordWeb")
      }
      else {
        this.props.navigation.navigate("EmailAccountForm")
      }
    }
  }
   handleRemove = (index:any) => {
   this. setState((prevState) => {
      const updatedEntries = [...prevState.veterinaryEntries];
      const updatedErrors = [...prevState.errors];

      updatedEntries.splice(index, 1);
      updatedErrors.splice(index, 1);

      return { ...prevState, veterinaryEntries: updatedEntries, errors: updatedErrors };
    });
  };
  handleTooltipCloseChanelsData = () => {
    this.setState({ tooltipOpenchannelHeadingTooltip: false });
  };
  handleHeadingMouseHover=()=>{
    this.setState({tooltipOpenchannelHeadingTooltip:true});
  };
   handleHeadingMouseOver=()=>{
    this.setState({tooltipOpenchannelHeadingTooltip:false});
  }
  handleTooltipCloseChanelsData2 = () => {
    this.setState({ toolTip2: false });
  };
  handleHeadingMouseHover2=()=>{
    this.setState({toolTip2:true});
  };
   handleHeadingMouseOver2=()=>{
    this.setState({toolTip2:false});
  }
  handleHeading2=()=>{
      this.setState({toolTip2:!this.state.toolTip2});
  }
   handleHeading=()=>{
      this.setState({tooltipOpenchannelHeadingTooltip:!this.state.tooltipOpenchannelHeadingTooltip});
  }

  
  hanldeSignUpResponse = (responseJson: any) => {
    if (responseJson?.data && Object.keys(responseJson?.data).length > 0) {
      this.setState({ success: true })
      removeStorageData("user_Id")
    }
    else if(responseJson?.errors[0] && responseJson?.errors[0].token=="Invalid token"){
      this.setState({open:true,info:"OH SNAP! TIME'S UP! Looks like your session decided to take a quick catnap! Fear not, just log back in and you'll be good to go."})
      removeStorageData("user_Id")
      setTimeout(() => {
        this.props.navigation.navigate("UserRole")
      }, 5000);
    }
    else if (responseJson?.errors[0]) {
      const errors = responseJson?.errors;
  
      if (errors) {
        errors.forEach((errorObject: any) => {
          const errorKey = Object.keys(errorObject)[0];

          switch (errorKey) {

            case "veterinary_clinics.contact_number":
              this.setState((prevState) => ({
                errors: [
                  {
                    ...prevState.errors[0],
                    contactNumberError: true,
                  },
                ],
              }));
              break;
            case "valid_date_of_birth":
              
              this.setState({ dateofbirthError: true, dateOfBirthMessage:errorObject?.valid_date_of_birth })
              break;
            case "full_phone_number":
              this.setState({ phoneError: true, });
              break;
            case "rescue_groups.representative_contact_number":
              this.setState({ groupContactError: true, });
              break;
              case "password_mismatch":this.setState({confirmPasswordError:true,passwordNotMatchMessage:errors[0]?.password_mismatch})
           break;
              default:
              break;
          }
        });
      }

    }
  }


  

  handleNavigateLogin = () => {
    this.props.navigation.navigate("EmailAccountLoginBlock")
  }
  closeSnackbar = () => {
    this.setState({ isSnackbarOpen: false })
  }

  closeSnackbar2 = () => {
    this.setState({ open: false })
  }

  

  
  


  hanldeRescueForm = (field: any, value: string, errorName: any,limit:number):boolean => {
    const inputValue =value?.trim();
    const maxLength = limit;
    if(inputValue.length==0){
      this.setState((prevState) => ({
        ...prevState,
        [field]: value,
        [errorName]: true
      }));
      return true
    }
  else  if ((inputValue.length > 0 || inputValue === ' ')) {
    this.setState((prevState) => ({
      ...prevState,
      [field]: value.substring(0, maxLength),
      [errorName]: false
    }));
    return false

  }
  return true
  

  }
  handlePetSelection = (value: string) => {
    this.setState((prevState) => {
      const { pets } = prevState;

      const updatedPets = pets.includes(value)
        ? pets.filter((pet) => pet !== value)
        : [...pets, value];

      return {
        pets: updatedPets,
        petsError: updatedPets.length === 0,
      };
    });
  };


  handleLink = (field: any, value: string, errorName: any) => {

    const urlPattern = /\bhttps?:\/\/\S+\b/;
    this.setState((prevState) => ({
      ...prevState,
      [field]: value,
      [errorName]: value === "" ? false : !urlPattern.test(value),
    }));
  }


  async componentDidMount() {
    const resetForgotImage=await storage.get("reset_password_screen_image");
    const otpVerifyImage=await storage.get("signup_screen_image");
    if(otpVerifyImage){

      this.setState({otpVerifyImage:otpVerifyImage})
    }
    if(resetForgotImage){

      this.setState({resetImage:resetForgotImage})
    }

    const signUpFormImage=await storage.get("signup_screen_image");
    if(signUpFormImage){

      this.setState({signUpFormImage:signUpFormImage})
    }
    const pathname = window.location.pathname
    window.location.pathname === ("/signup-otp" )&&
      this.startTimer();
      window.location.pathname === ("/password-recovery-otp" )&&
      this.startTimer();
    if (pathname === "/password-recovery-otp") {
      this.setState({ heading: "Password Recovery" })
    }
    else if(pathname==="/signup-otp" ) {
      this.setState({ heading: "Sign up to PETOOTI" })
    }
    if (window.location.pathname === "/signup-form") {
      this.handleTokenUrl()
      this.getAreaFunction()
      this.getNationalityFunction()
      this.getCountryFunction()
      this.getEmirateFunction()
      this.handleFetchUserProfile()
      this.handleInactivityCheck()
    }

    const type = await storage.get("user_type")
    const email = await storage.get("email")
    const firstName = await storage.get("firstName")
    this.setState({ userType: type, email: email, firstName: firstName })
  }
  handleClose = () => {
   this.props.navigation. navigate("LandingPageWeb");
    this.setState({ success: false })
  };
  handleEntryInputChange = (index: number, field: any, value: any) => {
    let correctValue:string;
   if(field === "veterinaryName"){
    correctValue= value.substring(0, 50)
    }
    else if(field === "clinicsName"){
      correctValue= value.substring(0, 100)

    }
    else{
      correctValue= value
    }
    this.setState((prevState) => {
      const updatedEntries = prevState.veterinaryEntries.map((entry: any, i: number) =>
        i === index ? { ...entry, [field]: correctValue} : entry
      );

      const updatedErrors = prevState.errors.map((error: any, i: number) =>
        i === index
          ? {
            ...error,
            [`${field}Error`]: this.validateField(field, value, updatedEntries[index].formattedValue),
          }
          : error
      );

      return {
        veterinaryEntries: updatedEntries,
        errors: updatedErrors,
        newEntry:
          index === prevState.veterinaryEntries.length
            ? { ...prevState.newEntry, [field]: value }
            : prevState.newEntry,
      };
    });
  };

  handleAddAnother = () => {
    const newErrors = {
      veterinaryNameError: false,
      clinicsNameError: false,
      contactNumberError: false,
    };

    this.setState((prevState) => ({
      veterinaryEntries: [
        ...prevState.veterinaryEntries,
        { ...prevState.newEntry },
      ],
      newEntry: {
        veterinaryName: "",
        clinicsName: "",
        contactNumber: "",
        formattedValue: "",
        formattedcontactNumber: ""

      },
      errors: [...prevState.errors, newErrors],
    }));
  };
  handleColorStyle = (field: keyof MyComponentState) => {
    const fieldValue = this.state[field];
    return fieldValue === "Select" ? "#94A3B8" : "#334155";
  };
  handleColorPhone = () => {
    return this.state.phoneError ? "#F87171" : "#CBD5E1"
  }
  handleVisiblPAssword = () => {
    return this.state.enablePasswordField ? "password" : "text"
  }
  handleVisiblConfirmPAssword = () => {
    return this.state.enableConfirmPasswordField ? "password" : "text"
  }
  areAllInputsFilled = () => {

    this.setState({ OtpError: this.state.inputs.length!==5, otpDone: this.state.inputs.length==5 });

  };
  handleGap=()=>{
     
    if(window.innerWidth<500){
      return "8px"
   }
   else{
return "27.5px"
   }

  }
  async componentWillUnmount() {
    clearInterval(this.timer);
    clearTimeout(this.timeoutId as NodeJS.Timeout);
      const events = ['mousemove', 'keydown', 'scroll', 'click'];
      events.forEach((event) => {
        window.removeEventListener(event, this.resetInactivityTimer);
      });
  }
  handleNavigation = () => {
    this.areAllInputsFilled()
    const check = this.state.inputs.length==5;
    if (check) {
      this.getOtpAPIfunction()
    }
  }

  getResendOtpForForgot = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const body = {
      "data": {
        "attributes": {
          "email": this.state.email
        }
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.forgotResentOtpId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_forgot_password/otps`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }


  getAreaFunction = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.areaApiId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/dropdowns/areas`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  getEmirateFunction = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.emirateApiId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/dropdowns/emirates`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  getNationalityFunction = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.nationalitiesApiId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/dropdowns/nationalities`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  getCountryFunction = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.countriesApiId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/dropdowns/countries`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  getOtpAPIfunction = async () => {
    const result = this.state.inputs;

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await storage.get("token")
    };
    const body = {
      "data": {
        "otp_code": +result,
        "token": await storage.get("otp_token")
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.otpverifyId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.optVerifyEndpoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }


  getSignUpAdopterFunction = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await storage.get("token")
    };
    let body;
    if (this.state.residence == '1') {
       body = {
      "data": {
        "attributes": {
          "token":await storage.get("otp_token"),
          "full_phone_number": this.state.fomatMObileNumber,
          "emirate_id": this.state.emirate,
          "area": this.state.area,
          "gender": this.state.gender,
          "date_of_birth": momentX.parseZone(this.state.dateofbirth).format("DD-MM-YYYY"),
          "nationality_id": this.state.nationality === "Select" ? "" : this.state.nationality,
          "country_id": this.state.residence,
          "password": this.state.password,
          "password_confirmation":this.state.confirmPassword,
          "pets_references": this.state.pets,
         
        }
      }
    }
    }else{
      body = {
        "data": {
          "attributes": {
            "token":await storage.get("otp_token"),
            "full_phone_number": this.state.fomatMObileNumber,
            "city": this.state.city,
            "gender": this.state.gender,
            "date_of_birth": momentX.parseZone(this.state.dateofbirth).format("DD-MM-YYYY"),
            "nationality_id": this.state.nationality === "Select" ? "" : this.state.nationality,
            "country_id": this.state.residence,
            "password": this.state.password,
            "password_confirmation":this.state.confirmPassword,
            "pets_references": this.state.pets
          }
        }
      }
    }
   
   
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.signupApiAdopterId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.signUpAdopterEndpoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  getSignUpRescuerFunction = async () => {
    let token;
    let urlParams = new URLSearchParams(window.location.search); 
    const urlToken = urlParams.get('token');
  
    if(urlToken!==null&&urlToken!==undefined&&urlToken!==''){
      token=urlToken
    }
    else{
      token=await getStorageData('otp_token')
    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const modifiedArray = this.state.veterinaryEntries.map((obj: any) => ({
      name: obj.veterinaryName,
      clinic_name: obj.clinicsName,
      contact_number: obj.contactNumber
    }));

    const body = {
      "data": {
        "attributes": {
          "facebook_url": this.state.facebookLink,
          "tiktok_url": this.state.tiktokLink,
          "linkedin_url": this.state.linkdinLink,
          "snapchat_url": this.state.snapchatLink,
          "instagram_url": this.state.instagramLink,
          "token":token,
          "has_rescue_group": !this.state.rescueCheck,
          "full_phone_number": this.state.fomatMObileNumber,
          "emirate_id": this.state.emirate,
          "area": this.state.area,
          "gender": this.state.gender,
          "date_of_birth": momentX.parseZone(this.state.dateofbirth).format("DD-MM-YYYY"),
          "nationality_id": this.state.nationality === "Select" ? "" : this.state.nationality,
          "password": this.state.password,
          "password_confirmation":this.state.confirmPassword,
          "pets_references": this.state.pets,
          "veterinary_clinics_attributes": modifiedArray,
          ...(this.state.rescueCheck
            ? {}
            : {
              "rescue_groups_attributes": [
                {
                  "name": this.state.rescueGroup,
       
                },
              ],
            }),

        }
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.signupApiRescuerId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.signUpAdopterEndpoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handleOtpFunction = () => {
    if (this.state.heading === "Password Recovery") {
      this.getResendOptFunction()
    }
    else {
      this.getResendOtpForForgot()
    }
  }
  getResendOptFunction = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const body = {
      "data": {
        "otp_type":"forgot_password",
        "attributes": {
          "email": await storage.get("email")
        }
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.resendApiId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.resendotpverifyEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  startTimer = () => {
    this.timer = setInterval(() => {
      this.updateTime();
    }, 1000);
  };

  resetTimer = () => {
    clearInterval(this.timer);
    this.setState({ time: { minutes: 0, seconds: 59 }, isActive: true }, () => {
      this.startTimer();
    });
  };

  updateTime = () => {
    const { time, isActive } = this.state;

    if (isActive) {
      if (time.minutes === 0 && time.seconds === 0) {
        this.setState({ isActive: false });
        clearInterval(this.timer);
      } else {
        this.setState((prevState) => {
          if (prevState.time.seconds === 0) {
            return {
              time: { minutes: prevState.time.minutes - 1, seconds: 59 },
            };
          } else {
            return {
              time: { ...prevState.time, seconds: prevState.time.seconds - 1 },
            };
          }
        });
      }
    }
  };

  formatTime = (value: any) => (value < 10 ? `0${value}` : `${value}`);

  handlePassword = (value: string) => {
  const special=configJSON.special
    
    this.setState({ password: value })

    const isLengthValid = value.length >= 5;
    if(!special.test(value)||!isLengthValid) {
      this.setState({ passwordError: true })

    }
    else if (isLengthValid) {
      this.setState({ passwordError: false })
    }
    

  }
  handleConfirmPassword = (value: string) => {
      
      this.setState({ confirmPassword: value })
      
      if(this.state.password!==value) {
        this.setState({ confirmPasswordError: true })
  
      }
      else {
        this.setState({ confirmPasswordError: false })
      }
      
  
    }

  handlePhoneNumber = (e:any) => {
    if (e && isValidPhoneNumber(e.toString())) {
      this.setState({ fomatMObileNumber: e, phoneError: false });
      return true;
    } else {
      this.setState({ fomatMObileNumber: e, phoneError: true });
      return false;
    }
  }

  dateOBirthHandle = (value: string) => {
    const dateFormat = /^\d{4}-\d{2}-\d{2}$/;
  
    this.setState({ dateofbirth: value });
    if (value == "") {
      this.setState({ dateofbirthError: true });
    }
    else if (!dateFormat.test(value)) {
      this.setState({ dateofbirthError: true });
    } 
    else {
      this.setState({ dateofbirthError: false });

    }
  }
  handleSeprateValidationSelect = (field: any, value: string, errorName: any) => {

  if(field=="area"||field=="city"){
    if(value.trim().length==0){
      this.setState((prevState) => ({
        ...prevState,
        [field]: value,
        [errorName]: true,
      }));

    }
  else  if ((value.trim().length > 0 || value === ' ')) {
    this.setState((prevState) => ({
      ...prevState,
      [field]: value.substring(0,50),
      [errorName]: false,
    }));
  }
  }
  else{
    this.setState((prevState) => ({
      ...prevState,
      [field]: value,
      [errorName]: value !== "" ? false : true,
    }))
  }
   
   
  }
  validateField = (field: string, value: any, formattedValue: any) => {

    if (field === 'veterinaryName' && value.trim().length==0) {
      return true;
    }
    if (field === 'clinicsName' && value.trim().length==0) {
      return true;
    }
    if (field === 'contactNumber' && (value == null || !(value && isValidPhoneNumber(value.toString())))) {
      return true;
    }
    return false;
  };
  handleValidtionAdopter = () => {
    this.handlePassword(this.state.password)
    const errors = {
      phoneError:
            !isValidPhoneNumber( this.state.fomatMObileNumber.toString()),
      areaError: this.state.area.trim().length === 0&&this.state.residence=='1',
      cityError: this.state.city.trim().length === 0&&this.state.residence!='1',
      genderError: this.state.gender === "Select",
      residenceError: this.state.residence === "Select",
      dateofbirthError: this.state.dateofbirth === "",
      emirateError: this.state.emirate === "Select"&&this.state.residence=='1',
      petsError: this.state.pets.length === 0,

    };

    this.setState(errors);
    const hasErrors = Object.values(errors).some(error => error);
    if (!this.state.passwordError && !hasErrors) {
      this.getSignUpAdopterFunction()
    }

  }

  validateRescueCheck = () => {
    if (!this.state.rescueCheck) {
      this.setState({
        groupContactError:!isValidPhoneNumber( this.state.groupContactValue.toString()),
        
        groupNameError: this.state.groupName === "",
        rescueGroupError: this.hanldeRescueForm("rescueGroup",this.state.rescueGroup, "rescueGroupError",100),
      });
    } else {
      this.setState({
        groupContactError: false,
        groupNameError: false,
        rescueGroupError: false,
      });
    }
  };
  handleValidationRescuer = () => {
    const urlPattern = /\bhttps?:\/\/\S+\b/;
    const { veterinaryEntries } = this.state;
    const errorsToUpdate = veterinaryEntries.map((entry: any) => ({
      veterinaryNameError: entry.veterinaryName.trim().length === 0,
      clinicsNameError: entry.clinicsName.trim().length === 0,
      contactNumberError:(
            !isValidPhoneNumber( entry.contactNumber.toString())
          ),
    }));
    this.setState({
      errors: errorsToUpdate,
    });
    this.handlePassword(this.state.password)
    this.handleConfirmPassword(this.state.confirmPassword)
    this.validateRescueCheck()
    const errors = {
      phoneError: (

            !isValidPhoneNumber( this.state.fomatMObileNumber.toString())

          ),

      areaError: this.state.area .trim().length==0,
      genderError: this.state.gender === "Select",
      dateofbirthError: this.state.dateofbirth === "",
      facebookLinkError: this.state.facebookLink === "" ? false : !urlPattern.test(this.state.facebookLink),
      tiktokLinkError: this.state.tiktokLink === "" ? false : !urlPattern.test(this.state.tiktokLink),
      snapchatLinkError: this.state.snapchatLink === "" ? false : !urlPattern.test(this.state.snapchatLink),
      linkdinLinkError: this.state.linkdinLink === "" ? false : !urlPattern.test(this.state.linkdinLink),
      instagramLinkError: this.state.instagramLink === "" ? false : !urlPattern.test(this.state.instagramLink),
      emirateError: this.state.emirate === "Select",
      petsError: this.state.pets.length === 0,

    };
    const allErrors = [...errorsToUpdate, errors];

    const hasErrors = allErrors.some((errorsObject) =>
      Object.values(errorsObject).some((error) => error)
    );
    this.setState(errors);
    if (!hasErrors && !this.state.passwordError) {
      if (this.state.rescueCheck || (!this.state.rescueGroupError)) {
        this.getSignUpRescuerFunction();
      }
    }

  }

  handleValidation = async () => {

    const user_type = await storage.get("user_type")
    if (user_type === "adopter") {
      this.handleValidtionAdopter()
    }
    else if (user_type == "rescuer") {
      this.handleValidationRescuer()
    }

  };
  convertFormat=()=>{
    if (!this.state.dateofbirth) return "";
    return momentX.parseZone(this.state.dateofbirth).format("DD/MM/YYYY");
     }
     
     openDatePicker() {
      this.setState({
        datePickerIsOpen: !this.state.datePickerIsOpen,
      });
    }
    handleChanges = (selectedDate:any) => {
      
      this.setState({ dateofbirth: selectedDate ,datePickerIsOpen:false,dateofbirthError:false});
    };
  handleFetchUserProfile=async()=>{
    let token
    let urlParams = new URLSearchParams(window.location.search); 
    const urlToken = urlParams.get('token');
      
    if(urlToken!==null&&urlToken!==undefined&&urlToken!==''){
      token=urlToken
    }
    else{
      token=await getStorageData('otp_token')
    }
    const profileId=await getStorageData('user_Id')
    if(profileId!==null&&profileId!==undefined||urlToken){
      const header = {
      "token": token
    };
   
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProfileApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.propfileEndPoints(profileId)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
    }
    
  }
  handleSetStateFunction=(data:any)=>{
this.setState({
  
  fomatMObileNumber:data?.full_phone_number?"+"+data?.full_phone_number:"",
  dateofbirth:data?.date_of_birth||"",
  emirate:data?.emirate?.id||"Select",
  area:data?.area||"",
  gender:data?.gender||"Select",
  nationality:data?.nationality?.id||"Select",
  rescueCheck:!data?.has_rescue_group||false,
  rescueGroup:data?.rescue_groups?.data[0]?.attributes?.name||"",
  groupName:data?.rescue_groups?.data[0]?.attributes?.representative_name||"",
  groupContact:data?.rescue_groups?.data[0]?.attributes?.representative_contact_number||"",
  groupContactValue:data?.rescue_groups?.data[0]?.attributes?.representative_contact_number||"",
  veterinaryEntries: data?.veterinary_clinics?.data?
    data?.veterinary_clinics.data.map((el:any)=>(
     { 
      veterinaryName: el.attributes.name,
      clinicsName: el.attributes.clinic_name,
      contactNumber: el.attributes.contact_number,
      formattedcontactNumber: el.attributes.phone_number,
      country:el.attributes.country_code}
    )):[
      {
        veterinaryName: "",
        clinicsName: "",
        contactNumber: "",
        formattedValue: "",
        formattedcontactNumber: "",
        country:""
      },
    ],

})
  }
  handleTokenUrl=()=>{
    let urlParams = new URLSearchParams(window.location.search); 

    const urlToken = urlParams.get('token');
    if(urlToken!==null&&urlToken!==undefined&&urlToken!==''){
      setStorageData('user_type','rescuer')
      setStorageData('url_token',urlToken)

   
    }   
  }
  // Customizable Area End
}
